<template>
  <div>
    <md-table v-model="list_data.mdData" md-card>
      <md-table-toolbar>
        <h1 class="md-title">Bancos</h1>
        <md-menu md-size="big" md-direction="bottom-end">
          <md-button class="md-icon-button" @click="go_to('/')">
            <md-icon>keyboard_backspace</md-icon>
          </md-button>
          <md-menu md-size="big" md-direction="bottom-end">
            <md-button class="md-icon-button" @click="go_to(`/form_bank`)">
              <md-icon>add</md-icon>
            </md-button>
          </md-menu>
          <md-menu md-size="auto">
            <md-button @click.prevent="showDialog=true">
              <md-icon >filter_list</md-icon>
            </md-button>
          </md-menu>
        </md-menu>
        <br>
        <p>Total items {{list_data.mdData.length}}</p>
      </md-table-toolbar>
      <md-table-row>
        <md-table-head class="md-xsmall-hide md-small-hide">#</md-table-head>
        <md-table-head>Nombres</md-table-head>
        <md-table-head >Teléfono</md-table-head>
        <md-table-head >Correo</md-table-head>
        <md-table-head >Dirección</md-table-head>
      </md-table-row>
      <md-table-row v-for="(item, index) in list_data.mdData" :key="item.id">
        <md-table-cell class="md-xsmall-hide md-small-hide" >{{index + 1}}</md-table-cell>
        <md-table-cell  md-sort-by="first_name">{{ item.name }}</md-table-cell>
        <md-table-cell>{{ item.phone }}</md-table-cell>
        <md-table-cell class="md-xsmall-hide md-small-hide">{{ item.email }}</md-table-cell>
        <md-table-cell>{{ item.address }}</md-table-cell>
        <md-table-cell >
          <md-button class="md-icon-button" @click="go_to(`/form_bank/${item.id}`)">
            <md-icon class="md-size-1x" >edit</md-icon>
          </md-button>
        </md-table-cell>
    </md-table-row>
    </md-table>
    <md-empty-state
      v-if="list_data.mdData.length === 0"
      md-icon="free_breakfast"
      md-label="Crear el primer banco"
      md-description="Aún no hay bancos registrados, crea el primero.">
      <md-button @click="go_to(`/form_bank/`)"
      md-theme="blue-button" class="md-accent md-raised">Crear banco</md-button>
    </md-empty-state>
    <md-progress-bar md-mode="indeterminate" v-if="sending_request" />
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Filtros bancos</md-dialog-title>
      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field >
              <label for="first_name">Nombre</label>
              <md-input @keyup.enter="list(1, rowsPerPage)"
              v-model="filters.first_name" type="text" :disabled="sending_request" />
            </md-field>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field >
              <label for="nit">NIT</label>
              <md-input @keyup.enter="list(1, rowsPerPage)"
              v-model="filters.nit" type="text" :disabled="sending_request" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field >
              <label for="phone">Teléfono</label>
              <md-input @keyup.enter="list(1, rowsPerPage)"
              v-model="filters.phone" type="text" :disabled="sending_request" />
            </md-field>
          </div>
        </div>
      </md-card-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog = false">Cerrar</md-button>
        <md-button class="md-primary" @click="reset_filters">Limpiar filtro</md-button>
        <md-button class="md-primary" @click="list(1, rowsPerPage)">Filtrar</md-button>
      </md-dialog-actions>
      <br>
      <br>
      <br>
    </md-dialog>
    <br>
    <br>
    <br>
  </div>
</template>
<script>

/* eslint-disable */
export default {
  name: 'Banks',
  created(){
    let current_filters = this.get_data_storage(this.name_storage_filter)
    if(current_filters){
      this.filters = current_filters
    }
    this.list(1, this.rowsPerPage)
    document.addEventListener('keyup', e => {
      if (e.key === 'f4' || e.keyCode === 115) {
        this.showDialog = true
      }
    })
  },
  data() {
    return {
      list_data: {
        mdCount: null,
        mdPage: null,
        mdData: []
      },
      showDialog: false,
      rowsPerPage: 10,
      filters: {
      },
      url: 'banks/',
      sending_request: false,
      name_storage_filter: 'filters_banks'
    };
  },
  methods: {
    go_to(url) {
      this.$router.push(url);
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    remove_storage(key) {
      localStorage.removeItem([key]);
    },
    get_data_storage(name) {
      return JSON.parse(localStorage.getItem(name));
    },
    reset_filters: function () {
      this.filters = {}
      this.remove_storage(this.name_storage_filter)
      this.list()
    },
    list(page, pageSize, sort, sortOrder) {
      let payload = {};
      const self = this;
      payload.company = localStorage.getItem('office')
      payload.paginate = "True"
      payload.page = page
      payload.per_page = pageSize
      if (this.sending_request) return false
      this.sending_request = true
      if (Object.keys(this.filters).length > 0){
        Object.assign(payload, this.filters)
      }
      this.sendRequest_with_url(this.url, payload).then((data) => {
        self.rowsPerPage = 50
        self.list_data = {
          mdCount: data.data.count,
          mdPage: page,
          mdData: data.data.results
        }

        this.sending_request = false
        if (this.showDialog){
          this.showDialog = false
        }
        this.save_storage(this.name_storage_filter, this.filters,)
      }).catch((error) => {
        this.sending_request = false
        this.handlerError(error)
      });
      return null;
    },
    async sendRequest(payload) {
      const result = await this.$http.get(this.url,{params:  payload});
      // const result = await this.$http.get(this.url, payload);
      return result;
    },
    async sendRequest_with_url(url, payload) {
      // const result = await this.$http.get(this.url, payload);
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    handlerError: function (error) {
      if (typeof (error.response.data) === 'object') {
        const result = error.response.data
        Object.entries(result).forEach(
          ([key, value]) => this.$swal.fire('Error!', key + ': ' + value, 'error')
        )
        return false
      }
      this.$swal.fire('Error!', error.response.data, 'error')
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    },
    space(value) {
      if ( value === '' || value === null ) return '-----'
      return value
    },
  },
};

</script>
<style lang="scss" scoped>
  .md-table + .md-table {
    margin-top: 16px
  }
  .avatar img {
    max-width: 30px;
  }
</style>
<style lang="css" scoped>
  .on-over {
    cursor: pointer;
  }
  .fa-refresh {
      -webkit-animation: infinite-spinning 5s normal linear infinite;
      animation: infinite-spinning 5s normal linear infinite;
  }
  .null{
    text-decoration: line-through;
  }
  .fade-enter-active {
    transition: all .5s ease;
  }
  .fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .fade-enter, .fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  @keyframes infinite-spinning {
    0% {
       -webkit-transform: rotate3d(0, 0, 1, 0deg);
       transform: rotate3d(0, 0, 1, 0deg);
     }
     25% {
       -webkit-transform: rotate3d(0, 0, 1, 90deg);
       transform: rotate3d(0, 0, 1, 90deg);
     }
     50% {
       -webkit-transform: rotate3d(0, 0, 1, 180deg);
       transform: rotate3d(0, 0, 1, 180deg);
     }
     75% {
       -webkit-transform: rotate3d(0, 0, 1, 270deg);
       transform: rotate3d(0, 0, 1, 270deg);
     }
     100% {
       -webkit-transform: rotate3d(0, 0, 1, 360deg);
       transform: rotate3d(0, 0, 1, 360deg);
     }
  }
  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
